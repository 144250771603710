.appCardHeaderText {
    font-size: calc(1.25em + 1vw) !important;
    font-weight: 600 !important;
    color: #047fbb;
}

.appUrlText {
    font-size: calc(.45em + 1vw) !important;
    font-weight: 550 !important;
    color: #047fbb;
}

.appText{
    font-size: calc(.45em + 1vw);
    font-weight: 500;
}

.bigDisclaimerText {
    font-size: calc(1.25em + 1vw) !important;
    font-weight: 600 !important;
    color: #eb0009;
}

@media only screen and (max-width: 1200px){
    /*Tablets [601px -> 1200px]*/
    .appText{
        font-size: calc(.75em + 1vw)
    }
}