/* GLOBAL STYLES */
.dataContainer {
    border: 2px solid #3EA5DC;
	border-radius: 8px;
	padding: 1em;
}

.headerText {
    font-size: 2.4em;
	font-weight: 600;
    color: #047fbb;
}

.listGroupHover .list-group-item:hover {
    background-color: #047fbb;
    color: #fff;
}

/* PAGINATOR */
.page-link {
    color: #047fbb !important;
}
  
.active > .page-link {
    color: #ffffff !important;
    background-color: #047fbb !important;
    border-color: #047fbb !important;
}

/* Active Tab Select */
a.apiLink.nav-link.active {
    /* hot pink, bold text for active tab title */
    background-color: #ecf6fb;
    color: #000000;
}

a:link {
	color: #4C4C4C;
	text-decoration: underline;
}
a:visited {
	color: #4C4C4C;
	text-decoration: underline;
}
a:hover {
	color: #4C4C4C;
	text-decoration: none;
}
a:active {
	color: #4C4C4C;
	text-decoration: none;
}

a.custom_white:link {
	color: #fff;
	text-decoration: underline;
}
a.custom_white:visited {
	color: #fff;
	text-decoration: underline;
}
a.custom_white:hover {
	color: #fff;
	text-decoration: none;
}
a.custom_white:active {
	color: #fff;
	text-decoration: none;
}

ul.list-group {
	margin: 0 0 1em 0;
}

ul.list-group span.custom {
	color: #fff;
	margin: 0 1em;
}

ul.list-group a {
	font-weight: 600;
	color: #4C4C4C;
	font-size: 1.1em;
}

form label {
    font-weight: 600;
}

h2 {
	color: #047FBB;
	font-weight: 600;
	font-size: 2.4em;
	margin: 0 0 0.4em 0;
}

h3 {
	color: #047FBB;
}

h3 a:link {
	color: #047FBB;
	text-decoration: underline;
}
h3 a:visited {
	color: #047FBB;
	text-decoration: underline;
}
h3 a:hover {
	color: #047FBB;
	text-decoration: none;
}
h3 a:active {
	color: #047FBB;
	text-decoration: none;
}


h4 {
	color: #047FBB;
}

h5 {
	color: #047FBB;
}

.draft {
	font-style: italic;
	color: #3EA5DC;
}

.form-error {
    color: #e46573 !important;
}

form label {
	font-weight: 600;
}

form button.custom {
	border: #047FBB;
	background-color: #047FBB;
}

form button.custom:hover {
	background-color: #3EA5DC;
}

form div.custom {
	margin: 0;
	padding: 0;
}

form.custom {
	margin: 0 0 1em 0;
}

form.custom label {
	margin: 0 1em 0 0;
}

form.custom input {
	margin: 0 1em 0 0;
}

form.custom p {
	margin: 1em 0;
}