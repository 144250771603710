header {
	text-align: center;
	margin: 0;
	padding: 0;
	height: '50px';
}

header div.client_banner {
	margin: 1em 0;
	padding: 0 0.2em;
}

header div.appconnect_banner {
	background-color: #047FBB;
}

header nav {
	background-color: #DCEFF9;
}

header nav ul li a.active {
	background-color: #fff;
	text-decoration: none;
	font-weight: 700;
}