.bannerWithHelp {
    margin: 0;
    padding: 0;
}

.bannerWithHelp h3 {
    font-size: 1.4em;
    margin: 0 0 0.6em 0;
}

.steps {
    background-color: #EFEFEF;
	padding: 0.2em;
	font-size: 1.2em;
	font-weight: bold;
	text-align: center;
}

@media screen and (min-width: 768px) {
    .steps {
        font-size: 1.4em;
    }

    .bannerWithHelp {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bannerWithHelp h3 {
        font-size: 1.6em;
    }
}

    