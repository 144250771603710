.optFormField {
    font-size: .75em;
    color: #047fbb;
}

form#contactInfo {
    border: 2px solid #adadad;
    border-radius: 8px;
    padding: 1em;
}

p span {
    color: #047fbb;
}