.refreshText {
    color: #4e843c;
    font-size: calc(.5em + 1vw);
    font-weight: 600;
    text-align: right;
}

.memberCard {
    border-color: #047fbb !important;
}

.memberCardHeader {
    background-color: #fff;
    border-color: #047fbb !important;
}

.memberCardHeaderText {
    font-size: calc(1.25em + 1vw) !important;
    font-weight: 600 !important;
}

.memberId {
    font-size: calc(.75em + 1vw);
    font-weight: 500;
}

.memberCardLink {
    font-weight: 500;
    text-decoration: underline;
    color: #047fbb;
}

.h1 {
    color: #047fbb;
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
}

.memberParagraph {
    padding-top: 20px;
}

#jumbo {
    margin: 0;
    padding: 15px;
    padding-bottom: 50px;
}

.noAppsHeader {
    color: #000000;
    padding: 0;
    margin: 0;
    font-size: 16px;
}

@media only screen and (max-width: 1200px){
    .refreshText{
        text-align: left;
    }
}