form#login {
	border: 2px solid #3EA5DC;
	border-radius: 8px;
	margin: 0 0 2em 0;
	padding: 0.6em;
	background-color: #EFEFEF;
}

.helpContainer {
    border: 1px solid #3EA5DC !important;
	border-radius: 8px !important;
	margin: 0 0 1em 0 !important;
	padding: 1em !important;
}

@media screen and (min-width: 768px) {
	form#login {
		margin: 0;
	}
	
	.helpContainer {
		border: none !important;
		margin: 0 !important;
		padding: 1em !important;
	}

	.left {
		margin: 0;
	}

	.right {
		text-align: left !important;
		border: 1px solid #3EA5DC !important;
		border-radius: 8px !important;
		margin: 0 !important;
		padding: 0.6em !important;
	}
}