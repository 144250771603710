#AdminHome h2 {display: none;}

div.fhir_section {
	border-top: 2px solid #047FBB;
	margin: 1em 0 2em 0;
	padding: 1em 0 0 0;
}

div.fhir_section input {
	border: 2px solid #3EA5DC;
}