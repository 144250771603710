.support div {
	border: 1px solid #047FBB;
	border-radius: 8px;	
	margin: 0 0 1em 0;
	padding: 0.6em 1em;
}

@media screen and (min-width: 768px) {
    .support {
        display: flex;
        justify-content: space-between;
    }
    
    .support div {
        width: 49%;
    }
}