.patient-pill  > a {
    color: #000 !important;
}
  
.patient-pill  > a:hover{
    color: #047fbb;
}
  
.patient-pill > a.active{
    background-color: #047fbb !important;
    color: #fff !important;
}
