.disclaimerText{
    font-size: calc(.45em + 1vw)
}

.cardHeaderText {
    font-size: calc(.45em + 1vw);
    font-weight: 500 ;
    color: #047fbb;
}


@media only screen and (max-width: 1200px){
    /*Tablets [601px -> 1200px]*/
    .disclaimerText{
        font-size: calc(.75em + 1vw)
    }
}